import { SaudiPhoneSchema } from "@/validations/SaudiPhoneInputSchema";
import { EmailReq } from "@/validations/String.schemas";
import { z } from "zod";

export const LoginSchema = (loginWith: any,t:any) => {
    return z.object({
      phone: loginWith === 'phone' 
        ? SaudiPhoneSchema(t)
        : 
      z.string().optional(),
    email: loginWith === 'mail'
      ? EmailReq(t)   
      : 
      z.string().optional()
    });
}

export type LoginSchemaType = {
  phone?: string;
  email?: string;
};