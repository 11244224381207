'use client'
import { toast } from "@/hooks/use-toast"
import { useUiStore } from "@/store/useUi.store"
import { useTranslations } from "next-intl"
import { useEffect, useState } from "react"


export default function TimeCounter({ seconds }: { seconds: number }) {
    const t = useTranslations('')
    const { setIsAuthModalOpen } = useUiStore()
    // The state for our timer
    const [timer, setTimer] = useState("00:00:00");
    const [hasExpired, setHasExpired] = useState(false);

    const getTimeRemaining = (e: number) => {
        const total = e - Date.now();
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor(
            (total / 1000 / 60 / 60) % 24
        );
        return {
            total,
            hours,
            minutes,
            seconds,
        };
    };

    const startTimer = (e: number) => {
        let { total, minutes, seconds } =
            getTimeRemaining(e);
        if (total >= 0) {

            setTimer(

                (minutes > 9
                    ? minutes
                    : "0" + minutes) +
                ":" +
                (seconds > 9 ? seconds : "0" + seconds)
            );
        }
    };

    const clearTimer = (e: number) => {

        setTimer("00:02:00");

        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
    };

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 120);
        return deadline;
    };

    useEffect(() => {
        clearTimer(getDeadTime().getTime());

    }, []);

    useEffect(() => {
        if (timer === '00:00' && !hasExpired) {
            setHasExpired(true);
            toast({
                title: t('The code has expired'),
                description: t('Please request a new code'),
                variant: 'destructive',
            });
            setIsAuthModalOpen(false);
        }
    }, [timer, hasExpired, setIsAuthModalOpen, t]);

    return <p className="text-xs text-center text-muted-foreground">
        {t('The code will expire in seconds', { seconds: timer })}
        {timer}
    </p>
}
