"use client";
import SubmitSection from "@/components/common/Buttons/SubmitSection";
import CustomPopup from "@/components/ui/CustomPopup";
import { useAccountDataStore } from "@/store/useAccountData.store";
import { RemoveCookies } from "@/utils/cookies.api";
import { useLocale, useTranslations } from "next-intl";
import { useRouter } from "next/navigation";
import { useState } from "react";

interface LogoutDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function LogoutDialog({ open, onClose }: LogoutDialogProps) {
  const { clearAccountData } = useAccountDataStore();
  const router = useRouter();
  const locale = useLocale();
  const [loading, setLoading] = useState(false);
  const onLogout = async () => {
    setLoading(true);
    await RemoveCookies();
    clearAccountData();
    onClose();
    router.push(`/${locale}`);
    setLoading(false);
    router.refresh();
    window.location.reload();
  };
  const t = useTranslations();
  const btnClasses =
    "px-[5px] font-[17px] w-full font-medium text-center rounded-[12px] h-[45px] py-[15px]";
  return (
    <CustomPopup
      isOpen={open}
      setIsOpen={onClose}
      className=" px-[24px] !rounded-[25px] py-[20px] "
    >
      <div className="rounded-lg bg-white w-[325px] p-[24px] ">
        <p className="text-center text-lg font-semibold">
          {t("logout_dialog_title")}
        </p>
        <div className="flex mt-[1.5rem] items-center flex-row-reverse gap-4">
          <SubmitSection
            className={btnClasses}
            onClick={onClose}
            disabled={false}
            buttonText={t("not_now")}
          />
          <SubmitSection
            className={`${btnClasses} !text-black !bg-gray-400`}
            onClick={onLogout}
            variant="outline"
            disabled={loading}
            buttonText={t("yes")}
          />
        </div>
      </div>
    </CustomPopup>
  );
}
