import { create } from 'zustand'

type Store = {
    isAuthModalOpen: boolean
    setIsAuthModalOpen: (isOpen?: boolean) => void
}

export const useUiStore = create<Store>()((set) => ({
    isAuthModalOpen: false,
    setIsAuthModalOpen: (isOpen) => set((state) => ({ isAuthModalOpen: isOpen !== undefined ? isOpen : !state.isAuthModalOpen }))
}))