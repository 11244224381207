"use client";

import { PostData } from "@/api/actoins/post.api";
import SubmitSection from "@/components/common/Buttons/SubmitSection";
import SaCodeNumber from "@/components/common/Inputs/SaCodeNumber";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { toast } from "@/hooks/use-toast";
import { useUiStore } from "@/store/useUi.store";
import { endpoints } from "@/utils/config";
import { zodResolver } from "@hookform/resolvers/zod";
import { Mail, Phone } from "lucide-react";
import { useLocale, useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LoginSchema } from "./Login.schema";
import OtpBtn from "./OtpBtn";

export type LoginWithType = "phone" | "mail" | "otp";
export default function LoginComponent() {
	const { isAuthModalOpen, setIsAuthModalOpen } = useUiStore();
	const [data, setData] = useState({
		id: "",
		sentTo: "",
	});
	const locale = useLocale();
	const [loginWith, setLoginWith] = useState<LoginWithType>("phone");
	const t = useTranslations();
	const loginForm = useForm({
		mode: "onBlur",
		resolver: zodResolver(LoginSchema(loginWith, t)),
	});
	const { handleSubmit, setValue, watch, control } = loginForm;
	const [loading, setLoading] = useState(false);
	const onSubmit = async (data: any) => {
		setLoading(true);
		const res = await PostData({
			endPoint: endpoints.login,
			data:
				loginWith === "mail"
					? { email: data.email }
					: { mobile: `+966${data.phone}` },
		});
		if (res?.success) {
			setData({
				id: res.id,
				sentTo: loginWith !== "phone" ? data.email : data.phone,
			});
			setLoginWith("otp");
			toast({
				title: t(`${res?.message}`),
			});
		} else {
			toast({
				title: t("error"),
				description: t(`${res?.message}`),
				variant: "destructive",
			});
		}
		setLoading(false);
	};
	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === "Enter") {
			handleSubmit(onSubmit)();
		}
	};
	useEffect(() => {
		if (isAuthModalOpen) {
			setLoginWith("phone");
		}
		return () => {
			setValue("phone", "");
			setValue("email", "");
			setLoginWith("phone");
		};
	}, [isAuthModalOpen]);
	return (
		<Dialog
			open={isAuthModalOpen}
			onOpenChange={() => {
				setIsAuthModalOpen(false);
				setValue("phone", "");
				setValue("email", "");
				setLoginWith("phone");
			}}
		>
			<DialogContent
				style={{
					boxShadow: "box-shadow: 0px 0px 30px #00000029;",
				}}
				className=" px-[24px] !rounded-[25px] py-[20px]  md:h-[552px] "
			>
				<div className="p-6 space-y-6 ">
					<div className="space-y-5 mt-9">
						<h2 className="text-xl font-semibold text-center">
							{t("Welcome")}
						</h2>
						<br />
						{loginWith !== "otp" ? (
							<Form {...loginForm}>
								<form
									onSubmit={handleSubmit(onSubmit)}
									className="space-y-4"
								>
									{loginWith == "mail" && (
										<FormField
											control={control}
											name="email"
											render={({ field }) => (
												<FormItem>
													<FormControl>
														<div
															className={`${locale == "en" ? "flex-row" : "flex-row-reverse"}  border-Gray border-[3px] border-Gray rounded-[12px] flex pt-[5px] pb-2 p-[14px] gap-2`}
														>
															<Input
																className={`flex-1 ${locale == "en" ? "text-left" : "text-right"} placeholder:text-[16px]  font-bold placeholder:tracking-widest	 placeholder:text-Grey border-Grey placeholder:font-normal border-0 shadow-none p-2 `}
																placeholder={"name@domain.com"}
																{...field}
																onKeyPress={handleKeyPress}
															/>
														</div>
													</FormControl>
													<FormMessage />
												</FormItem>
											)}
										/>
									)}
									{loginWith == "phone" && (
										<FormField
											control={control}
											name="phone"
											render={({ field }) => (
												<FormItem>
													<FormControl>
														<div
															className={`${locale == "en" ? "flex-row" : "flex-row-reverse"} ${loginWith == "phone" && watch("phone")?.length < 9 ? "border-Gray" : "border-green-400"} border-[3px] border-Gray rounded-[12px] flex pt-[5px] pb-2 p-[14px] gap-2`}
														>
															<div
																className={`${locale == "ar" ? "flex-row-reverse" : ""} flex items-center gap-2`}
															>
																<Image
																	src={
																		"/saudi-arabia-flag-icon.svg"
																	}
																	alt="saudi-arabia-flag-icon"
																	width={30}
																	height={30}
																/>
																<SaCodeNumber />
															</div>
															<Input
																type="tel"
																maxLength={10}
																inputMode="numeric"
																placeholder={t("50xxxxxxx")}
																onKeyPress={(e) => {
																	if (loginWith === "phone") {
																		// Keep existing number-only validation
																		if (
																			!/[0-9]/.test(e.key)
																		) {
																			e.preventDefault();
																		}
																	}
																	handleKeyPress(e);
																}}
																className={`flex-1 ${locale == "en" ? "text-left" : "text-right"} placeholder:text-[16px]  font-bold placeholder:tracking-widest	 placeholder:text-Grey border-Grey placeholder:font-normal border-0 shadow-none p-2 `}
																{...field}
																pattern="[0-9]*"
															/>
														</div>
													</FormControl>
													<FormMessage />
												</FormItem>
											)}
										/>
									)}
									<SubmitSection
										type="submit"
										buttonText={t("continue")}
										disabled={loading}
										className={`w-full ${loginWith == "phone" && watch("phone")?.length >= 9 ? "bg-Red !text-white" : "bg-gray-200"} hover:bg-gray-300 text-gray-900`}
									/>
								</form>
							</Form>
						) : (
							<OtpBtn
								setLoginWith={setLoginWith}
								sentTo={data.sentTo}
								id={data.id}
							/>
						)}

						{loginWith != "otp" && (
							<div className="space-y-4">
								<div className="relative">
									<div className="absolute inset-0 flex items-center">
										<span className="w-full border-t" />
									</div>
									<div className="relative w-full !mt-16 flex justify-center text-xs uppercase">
										<span className="bg-white px-2 text-muted-foreground">
											{t("or")}
										</span>
									</div>
								</div>

								<button className="w-full text-sm text-center ">
									{loginWith == "phone"
										? t("Use a different email or log in")
										: t("Use a different phone number or log in")}
								</button>

								<div className="flex justify-center">
									<button
										onClick={() => {
											setValue("phone", "");
											setValue("email", "");
											setLoginWith((prev) =>
												prev == "mail" ? "phone" : "mail",
											);
										}}
										className={`rounded-full ${loginWith == "phone" ? "bg-red-500" : "bg-green-500"} p-2`}
									>
										{loginWith == "phone" ? (
											<Mail className="h-5 w-5 text-white" />
										) : (
											<Phone className="h-5 w-5 text-white" />
										)}
									</button>
								</div>
							</div>
						)}
					</div>

					<p className="text-xs text-center text-muted-foreground">
						{t(
							"Registering on the site constitutes acceptance of the terms and conditions",
						)}{" "}
						<Link
							className=" text-Red"
							onClick={() => {
								setIsAuthModalOpen(false);
							}}
							href={`/${locale}/terms-and-conditions`}
						>
							{t("terms and conditions")}
						</Link>
					</p>
				</div>
			</DialogContent>
		</Dialog>
	);
}
