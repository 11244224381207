"use client";
import { cn } from "@/lib/utils";
import React, { useEffect, useRef } from "react";
import Popup from "./Popup";

function CustomPopup({
	children,
	isOpen,
	setIsOpen,
	onClose,
	className,
	mainClassName,
}: {
	className?: string;
	onClose?: () => void;
	mainClassName?: string;
	children?: React.ReactNode;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const popupRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				popupRef.current &&
				!popupRef.current.contains(event.target as Node)
			) {
				onClose && onClose();
				setIsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [setIsOpen]);

	return (
		<Popup
			className="w-screen h-screen fixed select-none top-0 left-0 z-10 bg-black bg-opacity-80"
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			style={{
				opacity: 1,
				backdropFilter: "blur(5px)",
				WebkitBackdropFilter: "blur(5px)",
				userSelect: "none",
				msUserSelect: "none",
			}}
		>
			<div
				ref={popupRef}
				className={cn(
					"flex justify-center items-center w-fit h-full md:container", // Center the popup
					mainClassName,
				)}
			>
				{children}
			</div>
		</Popup>
	);
}

export default CustomPopup;
