import { z } from "zod";

// The regex pattern validates:
// - Numbers starting with 009665, 9665, +9665, 05, or 5
// - Followed by a valid telecom prefix (5,0,3,6,4,9,1,8,7)
// - Followed by 7 digits
const SAUDI_PHONE_REGEX = /^(05|5)\d{8}$/;

export function SaudiPhoneSchema(t: (key: string) => string) {
  return z.string()
    .min(1, { message: t("Validations.required") })
    .regex(SAUDI_PHONE_REGEX, { message: t("Validations.invalid_soudia_phone_number") })
    .refine((val) => SAUDI_PHONE_REGEX.test(val), {
      message: t("Validations.invalid_soudia_phone_number"),
    });
}