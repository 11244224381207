import { setTokenCookie } from "@/api/actoins/cokkies.api";
import { PostData } from "@/api/actoins/post.api";
import SubmitSection from "@/components/common/Buttons/SubmitSection";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { toast } from "@/hooks/use-toast";
import { useAccountDataStore } from "@/store/useAccountData.store";
import { useUiStore } from "@/store/useUi.store";
import { endpoints } from "@/utils/config";
import { useTranslations } from "next-intl";
import { useState, useRef } from "react";
import TimeCounter from "./TimeCounter";

export default function OtpBtn({
  id,
  setLoginWith,
  sentTo,
  endpoint,
}: {
  sentTo?: string;
  endpoint?: string;
  id: string;
  setLoginWith: (loginWith: "phone" | "mail") => void;
}) {
  const t = useTranslations();
  const { setIsAuthModalOpen } = useUiStore();
  const [otpInput, setOtpInput] = useState("");

  const { setAccountData } = useAccountDataStore();
  const [loading, setLoading] = useState(false);

  const inputRefs = useRef<(HTMLInputElement | null | any)[]>([]);

  const onSubmit = async (e?: any) => {
    e && e.preventDefault();
    setLoading(true);
    const res = await PostData({
      endPoint: endpoint ?? endpoints.login,
      data: {
        id,
        otp: otpInput,
      },
    });
    if (res.success == true && res.data) {
      toast({
        title: t(`Welcome back`),
      });
      await setTokenCookie(res.token);
      const accountData = JSON.parse(JSON.stringify(res.data));
      setAccountData(accountData);
      setIsAuthModalOpen(false);
      setLoginWith("phone");
    } else {
      toast({
        title: t(`${res.message}`),
        variant: "destructive",
      });
      setOtpInput("");
    }
    setLoading(false);
  };
  return (
    <form className="flex text-center justify-center items-center flex-col gap-4">
      <h1 className=" font-bold">{t("otp")}</h1>
      <p className="text-[16px] text-gray-500">{t("otpSent")}</p>
      <p className="text-Red text-[18px] font-bold"> {sentTo}</p>
      <div
        className={` w-full  ${otpInput?.length < 4 ? "border-Gray" : "border-Gray"} border-[3px] border-Gray rounded-[12px] flex pt-[5px] pb-2 p-[14px] gap-2`}
      >
        <div className="flex justify-center w-full">
          <InputOTP
            autoFocus={true}
            name="otpInput"
            dir="ltr"
            onChange={(e) => setOtpInput(e)}
            maxLength={4}
          >
            <InputOTPGroup>
              {[0, 1, 2, 3].map((index) => (
                <InputOTPSlot
                  key={index}
                  index={index}
                  ref={(el) => {
                    inputRefs.current[index] = el;
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Backspace" &&
                      !(e.currentTarget as HTMLInputElement)?.value &&
                      index > 0
                    ) {
                      inputRefs.current[index - 1]?.focus();
                    }
                    if (
                      (e.currentTarget as HTMLInputElement).value &&
                      index < 3
                    ) {
                      inputRefs.current[index + 1]?.focus();
                    }
                  }}
                />
              ))}
            </InputOTPGroup>
          </InputOTP>
        </div>
      </div>
      <SubmitSection
        type="submit"
        buttonText={t("continue")}
        disableClick={otpInput.length != 4}
        disabled={loading}
        onClick={onSubmit}
        className={`${otpInput.length == 4 ? "bg-red-500 text-white" : "bg-gray-200 cursor-not-allowed text-gray-900"} py-3 rounded-lg hover:bg-gray-300 w-full`}
      />
      <TimeCounter seconds={10} />
    </form>
  );
}
