"use client";
import React, { useEffect, useRef } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import LangugueDropDownMenu from "./LangugueDropDownMenu";
import Link from "next/link";
import { useLocale } from "next-intl";
import { usePathname, useSearchParams } from "next/navigation";
import { MdOutlineLanguage } from "react-icons/md";
function LangugueSwitcher() {
  const [isDropDownOpen, setIsDropDownOpen] = React.useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const locale = useLocale();
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropDownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const url =
    pathname + (searchParams.toString() ? `?${searchParams.toString()}` : "");
  const path = url?.split("/")[1];
  return (
    <div ref={dropdownRef} className="relative text-Red ">
      <div
        className="text-Red flex items-center font-bold cursor-pointer"
        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
      >
        <h1 className="text-[16px]">
          <MdOutlineLanguage size={25} />
        </h1>
        <IoMdArrowDropdown width={"9px"} height={"5px"} />
      </div>
      {isDropDownOpen && (
        <LangugueDropDownMenu
          minWidth={"85px"}
          dropDownItems={[
            <Link href={url.replace(path, "en")} key="en">
              English
            </Link>,
            <Link href={url.replace(path, "ar")} key="ar">
              العربية
            </Link>,
          ]}
        />
      )}
    </div>
  );
}

export default LangugueSwitcher;
