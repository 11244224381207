'use client'
import { toast } from "@/hooks/use-toast";
import { useUiStore } from "@/store/useUi.store";
import { useLocale, useTranslations } from "next-intl";
import Link from "next/link";


export default function SupportLink({ isLoggedIn }: { isLoggedIn: boolean }) {

    const t = useTranslations()
    const locale = useLocale()
    const { setIsAuthModalOpen } = useUiStore()
    const handleSupportClick = () => {
        toast({
            title: t("you should login to sumbit a support ticket"),
            variant: "destructive",
        })
        setIsAuthModalOpen(true)

    }
    return (
        <div className="text-Red">
            {
                !isLoggedIn ?
                    <>
                        <div className=" cursor-pointer " onClick={handleSupportClick}>
                            {t("For technical support and inquiries")}
                        </div>
                    </> :
                    <Link href={`/${locale}/profile/technical-support`}>{t("For technical support and inquiries")}</Link>
            }
        </div>
    )
}