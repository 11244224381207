import { cn } from "@/lib/utils";
import { ZINDEX } from "@/utils/config";
import React, { useEffect, useRef } from "react";

function Popup({
  children,
  isOpen,
  setIsOpen,
  className,
  style,
}: {
  children?: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
  className?: string;
  style?: React.CSSProperties;
}) {
  const popupRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return isOpen ? (
    <div
      style={{
        zIndex: ZINDEX.popup,
        boxShadow: " 0px 0px 15px #00000029",
        ...(style || {}),
      }}
      ref={popupRef}
      className={cn(
        "absolute select-none bg-white rounded-xl  flex flex-col gap-[5px] px-2 py-1  text-center  text-sm ",
        className
      )}
    >
      {children}
    </div>
  ) : null;
}

export default Popup;
