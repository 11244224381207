"use client";
import Image from "next/image";
import Link from "next/link";
import { useSearchParams } from "next/navigation";

export default function CategoryCard({
	category,
	locale,
}: {
	category: any;
	locale: string;
}) {
	const searchParams = useSearchParams();
	const url =
		`/${locale}/search` +
		(searchParams.toString()
			? `?${searchParams.toString()}&Category=${category.id}`
			: `?Category=${category.id}`);
	return (
		<Link
			href={url}
			key={category.id}
			className="flex relative items-between  align-middle justify-center flex-col gap-2 items-center mx-auto"
			style={{ minWidth: "max-content" }}
		>
			<div className=" h-[50px] overflow-y-hidden">
				<Image
					src={category.image}
					alt={category.nameEn}
					className="flex-1"
					width={50}
					height={50}
				/>
			</div>
			<span className="font-medium   flex-0 text-[16px] text-center  whitespace-nowrap">
				{locale == "ar" ? category.nameAr : category.nameEn}
			</span>
		</Link>
	);
}
