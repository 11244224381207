"use client";
import { useUiStore } from "@/store/useUi.store";
import { useLocale, useTranslations } from "next-intl";
import Link from "next/link";
import { TbBuilding } from "react-icons/tb";
export default function NavbarAddBuildingSectionClient({
	isLoggedIn,
}: {
	isLoggedIn: boolean;
}) {
	const t = useTranslations("");
	const { setIsAuthModalOpen } = useUiStore();
	const locale = useLocale();
	const btnClassName =
		"bg-LightBlue duration-300  font-bold hover:bg-[#59939e]  text-[11px] py-[6.2px]  text-[12px] text-White rounded-[32px] p-0  px-[14.39px] flex items-center gap-[7.66px]";
	return (
		<>
			{!isLoggedIn ? (
				<button
					className={btnClassName}
					onClick={() => setIsAuthModalOpen(true)}
				>
					<TbBuilding size={18} />
					{t("add-property")}
				</button>
			) : (
				<Link
					href={`/${locale}/profile/new-property`}
					className={btnClassName}
				>
					<TbBuilding />
					{t("add-property")}
				</Link>
			)}
		</>
	);
}
