"use client";
import LogoutDialog from "@/components/pages/logout/LogoutDialog";
import { useAccountDataStore } from "@/store/useAccountData.store";
import { Bell, Heart, Settings, SquareChartGantt } from "lucide-react";
import { useLocale, useTranslations } from "next-intl";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { IoMdLogOut } from "react-icons/io";
import LangugueDropDownMenu from "./LangugueDropDownMenu";
import Image from "next/image";
import { MdOutlineSupportAgent } from "react-icons/md";

export default function ProfileNavSection({
	isLoggedIn,
}: {
	isLoggedIn: boolean;
}) {
	const { accountData, clearAccountData } = useAccountDataStore();
	if (!isLoggedIn && accountData.id != -1) {
		clearAccountData();
	}
	const [isDropDownOpen, setIsDropDownOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const handleClickOutside = (event: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as Node)
		) {
			setIsDropDownOpen(false);
		}
	};
	const capitalizeFirstLetter = (str?: string) => {
		if (!str) return "";
		return str.charAt(0).toUpperCase() + str.slice(1);
	};
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);
	const t = useTranslations("");
	const locale = useLocale();
	const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
	return (
		<>
			<div
				ref={dropdownRef}
				className="relative text-Red"
			>
				<div
					className="text-Red flex gap-3 items-center font-bold cursor-pointer"
					onClick={() => setIsDropDownOpen(!isDropDownOpen)}
				>
					<h1 className="text-[13px] font-bold">
						{accountData?.firstName
							? `${capitalizeFirstLetter(accountData?.firstName)}`
							: t("profile name")}
					</h1>
					<Image
						src={accountData?.image ? accountData?.image : "/avatar.avif"}
						className={
							"rounded-full !w-[28px] !h-[28px] border-[2px] border-red"
						}
						width={34}
						height={34}
						alt="profile"
					/>
				</div>
				{isDropDownOpen && (
					<LangugueDropDownMenu
						minWidth={"205px"}
						className="!ps-4 !pe-5"
						dropDownItems={[
							<Link
								href={`/${locale}/profile/profile`}
								className="flex items-center  group w-full  gap-3 font-semibold text-black"
								key="settings"
							>
								{" "}
								<Settings
									className=" transition group-hover:!text-Red "
									size={16}
								/>
								{t("userProfile")}{" "}
							</Link>,
							<Link
								href={`/${locale}/profile/notifications`}
								className="flex items-center   group gap-3  font-semibold text-black"
								key="ar"
							>
								<Bell
									className=" transition group-hover:text-Red"
									size={16}
								/>{" "}
								{t("notifications")}{" "}
							</Link>,
							<Link
								href={`/${locale}/profile/reservation-management`}
								className="flex items-center w-max  group gap-3 font-semibold text-black"
								key="ar"
							>
								<SquareChartGantt
									className=" transition group-hover:text-Red"
									size={16}
								/>{" "}
								{t("reservation Management")}{" "}
							</Link>,
							<Link
								href={`/${locale}/profile/technical-support`}
								className="flex items-center w-full  group gap-3 font-semibold text-black"
								key="ar"
							>
								<MdOutlineSupportAgent
									className=" transition w-max group-hover:text-Red"
									size={16}
								/>{" "}
								{t("technical support")}{" "}
							</Link>,
							<Link
								href={`/${locale}/profile/wishlist`}
								className="flex items-center   group gap-3 font-semibold text-black"
								key="ar"
							>
								<Heart
									className=" transition group-hover:text-Red"
									size={16}
								/>{" "}
								{t("favourites")}{" "}
							</Link>,

							<button
								onClick={() => {
									setIsDropDownOpen(false);
									setIsLogoutDialogOpen(true);
								}}
								style={{
									width: "max-content",
								}}
								className="flex items-center justify-center flex-row-reverse w-full text-black  group gap-3"
								key="en"
							>
								{t("logout")}{" "}
								<IoMdLogOut
									className=" transition group-hover:text-Red"
									size={16}
								/>
							</button>,
						]}
					/>
				)}
			</div>
			<LogoutDialog
				open={isLogoutDialogOpen}
				onClose={() => setIsLogoutDialogOpen(false)}
			/>
		</>
	);
}
