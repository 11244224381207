"use client";
import LoginComponent from "@/components/pages/login/Login";
import { useUiStore } from "@/store/useUi.store";
import { useTranslations } from "next-intl";
import { useState } from "react";

export default function LoginButton() {
	const { isAuthModalOpen, setIsAuthModalOpen } = useUiStore();
	const t = useTranslations();
	return (
		<>
			<button
				className="bg-Red hover:bg-[#c91b00] font-bold  duration-300 py-[7px] px-[15px] rounded-full text-white text-[11.5px]"
				onClick={() => setIsAuthModalOpen(true)}
			>
				<div className=" md:flex hidden">{t("Login or Sign Up")}</div>
				<div className="flex md:hidden">{t("signin")}</div>
			</button>
			{isAuthModalOpen && <LoginComponent />}
		</>
	);
}
