"use client";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import { useLocale } from "next-intl";
import { useRef } from "react";
import CategoryCard from "./CategoryCard";

export default function PropertyCategoriesSwiper({
	categories,
}: {
	categories: any;
}) {
	const locale = useLocale();
	const plugin = useRef(
		Autoplay({
			delay: 2000,
			stopOnInteraction: false,
			stopOnMouseEnter: true,
		}),
	);

	return (
		<div className="md:hidden relative">
			<Carousel
				plugins={[plugin.current]}
				onMouseEnter={plugin.current.stop}
				onMouseLeave={plugin.current.reset}
				opts={{
					loop: true,
					direction: locale === "ar" ? "rtl" : "ltr",
					dragFree: true,
					containScroll: "trimSnaps",
				}}
				className="w-full"
				onEnded={() => plugin.current.play()}
			>
				<CarouselContent className="-ml-2 flex items-center">
					{categories?.data?.map((category: any) => (
						<CarouselItem
							key={category.id}
							className="pl-2 basis-auto min-w-[100px]"
						>
							<CategoryCard
								category={category}
								locale={locale}
							/>
						</CarouselItem>
					))}
				</CarouselContent>
				<CarouselPrevious className="hidden md:flex" />
				<CarouselNext className="hidden md:flex" />
			</Carousel>
		</div>
	);
}
