import { getToken } from "@/utils/cookies.api";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type AccountDataT = {
	id: number;
	email: string;
	firstName: string;
	lastName: string;
	phone: string;
	birthDate: string;
	nationalityOrPassport: string;
	gender: string;
	address: string;
	photo?: string;
	street?: string;
	street2?: string;
	city?: string;
	country?: string;
	postalCode?: string;
	image?: string;
	district?: string;
	nationalId: string;
	mobile: string;
};

type Store = {
	accountData: AccountDataT;
	setAccountData: (data: AccountDataT) => void;
	checkToken: () => Promise<void>;
	clearAccountData: () => void;
};

export const useAccountDataStore = create<Store>()(
	persist(
		(set) => ({
			accountData: {
				id: -1,
				email: "",
				firstName: "",
				lastName: "",
				phone: "",
				birthDate: "",
				nationalityOrPassport: "",
				gender: "",
				address: "",
				nationalId: "",
				image: "",
				mobile: "",
			},
			setAccountData: (data: AccountDataT) =>
				set((state) => {
					if (data.address) {
						const [street, street2, district, postalCode, city] =
							data?.address?.split(",");
						data = {
							...data,
							street: street?.toString().replace("street:", ""),
							street2: street2?.replace("street2:", ""),
							city: city?.replace("city:", ""),
							district: district?.replace("district:", ""),
							postalCode: postalCode?.replace("postalCode:", ""),
						};
					}
					state.accountData = { ...state.accountData, ...data };
					return state;
				}),
			checkToken: async () => {
				const token = await getToken();
				// if token exiest and id =-1 fetch data again
			},
			clearAccountData: () =>
				set((state) => {
					state.accountData = {
						id: -1,
						email: "",
						firstName: "",
						lastName: "",
						phone: "",
						birthDate: "",
						nationalityOrPassport: "",
						gender: "",
						address: "",
						nationalId: "",
						mobile: "",
					};
					return state;
				}),
		}),
		{
			name: "account-data-storage",
			storage: createJSONStorage(() => localStorage),
		},
	),
);
