import { Button } from "@/components/ui/button";
import { ReloadIcon } from "@radix-ui/react-icons";

function SubmitSection({
  disabled,
  buttonText,
  className,
  onClick,
  variant,
  disableClick,
  form,
  type,
}: {
  form?: string;
  disabled: boolean;
  buttonText?: string;
  type?: "submit" | "button";
  disableClick?: boolean;
  variant?:
    | "link"
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | null
    | undefined;
  className?: string;
  onClick?: (e?: any) => void;
}) {
  return (
    <div
      className={` ${disabled && "!cursor-not-allowed"} flex items-center w-full !mt-3 justify-end gap-2`}
    >
      <Button
        form={form}
        variant={variant ?? "default"}
        className={` w-full ${className}`}
        type={type ?? "submit"}
        disabled={disableClick ?? disabled}
        onClick={onClick}
      >
        {disabled && <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />}
        {buttonText}
      </Button>{" "}
    </div>
  );
}

export default SubmitSection;
