import { cn } from "@/lib/utils";
import { useLocale } from "next-intl";

function LangugueDropDownMenu({
	dropDownItems,
	minWidth,
	className,
}: {
	className?: string;
	minWidth?: string;
	dropDownItems: any[];
}) {
	const locale = useLocale();
	return (
		<div
			style={{
				zIndex: "40 !important",
				boxShadow: " 0px 0px 6px #00000029",
				minWidth: minWidth ?? "inherit",
			}}
			className={cn(
				`absolute bg-White rounded-xl top-8 ${locale == "ar" ? "-left-[40%]" : "-right-[40%]"} gap-[5px] py-[15px]   flex  flex-col  w-[90px] h-fit   font-medium  text-center shadow-md  text-sm`,
				className,
			)}
		>
			{dropDownItems.map((item, index) => (
				<button
					key={index}
					className=" cursor-pointer text-[13px]"
				>
					{item}
				</button>
			))}
		</div>
	);
}

export default LangugueDropDownMenu;
